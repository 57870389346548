import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const SEO = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const commonLdJson = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: 'Motor Insurance',
    brand: 'Tameeni',
    image: 'https://www.tameeni.com/Resources/images/header-logo.svg%22',
    aggregateRating: { '@type': 'AggregateRating', ratingValue: 4.8, ratingCount: 5479 },
  };
  const arLdJsonPt1Of3 = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'تأميني',
    legalName: 'تأميني',
    url: 'https://www.tameeni.com',
    logo: 'https://www.tameeni.com/Resources/images/header-logo.svg',
    foundingDate: '2016',
    founders: [
      {
        '@type': 'Person',
        name: 'سليمان عبدالله الفلاج',
      },
      {
        '@type': 'Person',
        name: 'ثامر عبدالله الفلاج',
      },
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: '7933 المهندس مساعد العنقري',
      addressLocality: 'السليمانية',
      addressRegion: 'RUH',
      postalCode: '12245',
      addressCountry: 'KSA',
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'خدمة العملاء',
      telephone: '[8002444455]',
      email: 'support@tameeni.com',
    },
    sameAs: [
      'https://www.instagram.com/tameeniksa',
      'https://twitter.com/TameeniKSA',
      'https://www.snapchat.com/add/tameeniksa',
      'https://www.facebook.com/Tameeniksa/',
      'https://www.youtube.com/channel/UCRLnHZkwAWJW0UN5CvA6Xsg/',
      'https://www.linkedin.com/company/tameeniksa/',
    ],
  };
  const arLdJsonPt2Of3 = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'ما هو الفرق بين تأمين ضد الغير (طرف ثالث) والتأمين الشامل؟',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'يوفر تأمين ضد الغير (طرف ثالث) تغطية المسؤولية تجاه الغير فقط، وذلك حسب الشروط والاستثناءات الموضحة في وثيقة التأمين.علماً أن هذا التأمين يعد تأميناً إلزامياً حسب أنظمة المملكة العربية السعودية . التأمين الشامل : يوفر التأمين الشامل تغطية شاملة للمركبة المؤمن عليها وكذلك تغطية المسؤولية تجاه الغير. كما يشمل التأمين الشامل عادةً الخسارة أو الضرر للمركبة المؤمن عليها من الحوادث والحريق والسرقة، وذلك حسب الشروط والاستثناءات الموضحة في وثيقة التأمين',
        },
      },
      {
        '@type': 'Question',
        name: 'ماهي الوثيقة الموحدة للتأمين الإلزامي على المركبات؟',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'الوثيقة الموحدة للتأمين الإلزامي على المركبات هي وثيقة تحتوي على القواعد العامة والشروط للتأمين .والتي أصدرتها مؤسسة النقد العربي السعودي. ',
        },
      },
      {
        '@type': 'Question',
        name: 'هل موقع تأميني معتمد ومصرّح من الجهات الرسمية؟',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'نعم، موقع تأميني هو أول موقع إلكتروني لشراء وثائق التأمين تم ترخصيه من مؤسسة النقد العربي السعودي.',
        },
      },
    ],
  };
  const enLdJsonPt1Of3 = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Tameeni',
    legalName: 'Tameeni',
    url: 'https://www.tameeni.com/en',
    logo: 'https://www.tameeni.com/Resources/images/header-logo.svg',
    foundingDate: '2016',
    founders: [
      {
        '@type': 'Person',
        name: 'Suliman Alfallaj',
      },
      {
        '@type': 'Person',
        name: 'Thamer Alfallaj',
      },
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: '7933 Al Muhandis Masaid Al Anqari',
      addressLocality: 'As Sulimaniyah',
      addressRegion: 'RUH',
      postalCode: '12245',
      addressCountry: 'KSA',
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer care',
      telephone: '[8002444455]',
      email: 'support@tameeni.com',
    },
    sameAs: [
      'https://www.instagram.com/tameeniksa',
      'https://twitter.com/TameeniKSA',
      'https://www.snapchat.com/add/tameeniksa',
      'https://www.facebook.com/Tameeniksa/',
      'https://www.youtube.com/channel/UCRLnHZkwAWJW0UN5CvA6Xsg/',
      'https://www.linkedin.com/company/tameeniksa/',
    ],
  };
  const enLdJsonPt2Of3 = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'What is the unified policy for compulsory vehicle Insurance?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The Unified Policy for Compulsory Vehicle Insurance is a document containing general rules and conditions for insurance issued by the Saudi Arabian Monetary Agency.',
        },
      },
      {
        '@type': 'Question',
        name: 'What is the difference between third party insurance and comprehensive insurance?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Third party (third party) insurance provides third party liability coverage only, as per the terms and conditions set out in the insurance policy. Note that this insurance is mandatory according to the regulations of the Kingdom of Saudi Arabia. Comprehensive insurance provides comprehensive coverage of the insured vehicle as well as third party liability coverage. Comprehensive insurance usually covers loss or damage to insured vehicle from accidents, fire and theft, as per the terms and exceptions set forth in the insurance policy.',
        },
      },
      {
        '@type': 'Question',
        name: 'What is the residence number?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'It is the number on the Resident ID card which consists of ten digits.',
        },
      },
    ],
  };
  const arLdJsonPt3Of3 = {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'الرئيسية',
        item: 'https://www.tameeni.com/',
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'تأمين مركبات',
        item: 'https://www.tameeni.com/',
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: 'تأمين طبي للشركات الصغيرة والمتوسطة',
        item: 'https://health.tameeni.com/',
      },
      {
        '@type': 'ListItem',
        position: 4,
        name: 'تأمين العمالة المنزلية',
        item: 'https://dh.tameeni.com/',
      },
      {
        '@type': 'ListItem',
        position: 5,
        name: 'التأمين الأخطاء الطبية',
        item: 'https://mmp.tameeni.com/',
      },
      {
        '@type': 'ListItem',
        position: 6,
        name: 'تأمين السفر',
        item: 'https://travel.tameeni.com/',
      },
      {
        '@type': 'ListItem',
        position: 7,
        name: 'فحص تاريخ المركبة (موجز)',
        item: 'https://www.tameeni.com/car/ar/Vehicle-History-Check-Mojaz',
      },
      {
        '@type': 'ListItem',
        position: 8,
        name: 'الباقة الذكية',
        item: 'https://www.tameeni.com/car/ar/Smart-Package',
      },
    ],
  };
  const enLdJsonPt3Of3 = {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: 'https://www.tameeni.com/en',
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Motor Insurance',
        item: 'https://www.tameeni.com/en',
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: 'SME Health Insurance',
        item: 'https://health.tameeni.com/en',
      },
      {
        '@type': 'ListItem',
        position: 4,
        name: 'Domestic Helper Insurance',
        item: 'https://dh.tameeni.com/en',
      },
      {
        '@type': 'ListItem',
        position: 5,
        name: 'Medical Malpractice Insurance',
        item: 'https://mmp.tameeni.com/en',
      },
      {
        '@type': 'ListItem',
        position: 6,
        name: 'Travel Insurance',
        item: 'https://travel.tameeni.com/en',
      },
      {
        '@type': 'ListItem',
        position: 7,
        name: 'Vehicle History - Mojaz',
        item: 'https://www.tameeni.com/car/en/Vehicle-History-Check-Mojaz',
      },
      {
        '@type': 'ListItem',
        position: 8,
        name: 'Smart Package',
        item: 'https://www.tameeni.com/car/en/Smart-Package',
      },
    ],
  };

  return (
    <Helmet>
      <script type='application/ld+json'>{JSON.stringify(commonLdJson)}</script>
      {language === 'en' ? (
        <script type='application/ld+json'>{JSON.stringify(enLdJsonPt1Of3)}</script>
      ) : (
        <script type='application/ld+json'>{JSON.stringify(arLdJsonPt1Of3)}</script>
      )}
      {language === 'en' ? (
        <script type='application/ld+json'>{JSON.stringify(enLdJsonPt2Of3)}</script>
      ) : (
        <script type='application/ld+json'>{JSON.stringify(arLdJsonPt2Of3)}</script>
      )}
      {language === 'en' ? (
        <script type='application/ld+json'>{JSON.stringify(enLdJsonPt3Of3)}</script>
      ) : (
        <script type='application/ld+json'>{JSON.stringify(arLdJsonPt3Of3)}</script>
      )}
    </Helmet>
  );
};

export default SEO;
