const useBannerClick = () => {
  const handleClick = () => {
    const userAgent = navigator.userAgent;
    // Check for iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open(
        'https://apps.apple.com/us/app/tameeni-%D8%AA%D8%A3%D9%85%D9%8A%D9%86%D9%8A/id1501339487',
        '_blank',
      );
    }
    // Check for Android
    else if (/android/i.test(userAgent)) {
      if (/huawei/i.test(userAgent)) {
        // Huawei device detected
        window.open('https://appgallery.huawei.com/app/C104656303', '_blank');
      } else {
        // Android device detected (not Huawei)
        window.open(
          'https://play.google.com/store/apps/details?id=tameeni.com&pcampaignid=web_share',
          '_blank',
        );
      }
    } else {
      window.open('/TameeniOffers');
    }
  };
  return { handleClick };
};

export default useBannerClick;
