import Img from '@Components/common/Img';
import { THomeBanner } from '@models/home.model';
import { useEffect, useState } from 'react';
import { Autoplay, Navigation } from 'swiper/modules';
import { SwiperSlide, Swiper } from 'swiper/react';
import ApplicationService from '@Services/motor/application';
import { useTranslation } from 'react-i18next';
import TameeniLoader from '@Components/common/TameeniLoader';
import useBannerClick from '@Hooks/useBannerClick';

const AdsBanner = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('home');
  const [showBanner, setShowBanner] = useState<boolean>(
    !sessionStorage.getItem('isHeaderBannerDismissed'),
  );
  const [isGetAppDismissed, setIsGetAppDismissed] = useState<boolean>(
    !!sessionStorage.getItem('isGetAppDismissed'),
  );
  const [homeBanners, setHomeBanners] = useState<THomeBanner[] | null>(null);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { handleClick } = useBannerClick();

  useEffect(() => {
    setIsLoading(true);
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    ApplicationService.getHomeBenner()
      .then((res) => {
        setHomeBanners(res);
        if (res.length <= 0) setShowBanner(false);
      })
      .catch(() => {
        setShowBanner(false);
        // DUMMY BANNERS FOR TESTING
        // setHomeBanners([
        //   {
        //     id: 0,
        //     imageAr: '/Resources/images/header/announcement.png',
        //     imageEn: '/Resources/images/header/announcement.png',
        //     mobileImageAr: '/Resources/images/header/announcement-mobile.png',
        //     mobileImageEn: '/Resources/images/header/announcement-mobile.png',
        //     name: 'name',
        //     redirectionLink: 'https://www.google.com',
        //     status: 0,
        //   },
        //   {
        //     id: 1,
        //     imageAr: '/Resources/images/header/announcement.png',
        //     imageEn: '/Resources/images/header/announcement.png',
        //     mobileImageAr: '/Resources/images/header/announcement-mobile.png',
        //     mobileImageEn: '/Resources/images/header/announcement-mobile.png',
        //     name: 'name',
        //     redirectionLink: 'https://www.google.com',
        //     status: 0,
        //   },
        //   {
        //     id: 2,
        //     imageAr: '/Resources/images/header/announcement.png',
        //     imageEn: '/Resources/images/header/announcement.png',
        //     mobileImageAr: '/Resources/images/header/announcement-mobile.png',
        //     mobileImageEn: '/Resources/images/header/announcement-mobile.png',
        //     name: 'name',
        //     redirectionLink: 'https://www.google.com',
        //     status: 0,
        //   },
        //   {
        //     id: 3,
        //     imageAr: '/Resources/images/header/announcement.png',
        //     imageEn: '/Resources/images/header/announcement.png',
        //     mobileImageAr: '/Resources/images/header/announcement-mobile.png',
        //     mobileImageEn: '/Resources/images/header/announcement-mobile.png',
        //     name: 'name',
        //     redirectionLink: 'https://www.google.com',
        //     status: 0,
        //   },
        // ]);
      })
      .finally(() => setIsLoading(false));
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 767.99) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowWidth]);

  if (isLoading) return <TameeniLoader />;

  return (
    <>
      {isMobile && !isGetAppDismissed ? (
        <div className='flex justify-between gap-1 flex-wrap items-center py-2 px-4 bg-gold-gradient'>
          <div className='flex gap-2 items-center'>
            <div className='bg-white p-2 flex justify-center items-center rounded-sm w-12 h-12'>
              <Img src='/Resources/images/tameeni-icon-blue1.svg' width={20} />
            </div>
            <div className='flex flex-col justify-center'>
              <span className='font-bold'>{t('home:get_app_title')}</span>
              <span>{t('home:get_app_subtitle')}</span>
            </div>
          </div>
          <div className='flex gap-2 items-center'>
            <button
              className='bg-primary px-4 py-2 rounded-sm text-white font-bold cursor-pointer'
              onClick={handleClick}
            >
              {t('home:get_app_action')}
            </button>
            <div className='flex'>
              <span
                className='font-bold text-2xl cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  sessionStorage.setItem('isGetAppDismissed', 'true');
                  setIsGetAppDismissed(true);
                }}
              >
                &times;
              </span>
            </div>
          </div>
        </div>
      ) : (
        showBanner &&
        homeBanners &&
        homeBanners?.length > 0 && (
          <Swiper
            slidesPerView={1}
            modules={[Autoplay, Navigation]}
            autoHeight={true}
            loop={true}
            speed={500}
            dir={language === 'en' ? 'ltr' : 'rtl'}
            key={language}
            autoplay
          >
            {(isMobile ? homeBanners : [homeBanners[0]])?.map((banner) => {
              return (
                <SwiperSlide key={`banner-${banner.id}`}>
                  <div
                    className='bg-white w-full h-auto relative cursor-pointer'
                    onClick={() => window.open(banner.redirectionLink, '_blank')}
                  >
                    <Img
                      src={
                        isMobile
                          ? language === 'en'
                            ? banner.mobileImageEn
                            : banner.mobileImageAr
                          : language === 'en'
                            ? banner.imageEn
                            : banner.imageAr
                      }
                      className='w-full h-[calc(100vw*103/375)] md:h-[calc(100vw*100/1440)]'
                    />
                    <span
                      className='absolute top-1 end-2 cursor-pointer text-md md:text-2xl font-bold text-white'
                      onClick={(e) => {
                        e.stopPropagation();
                        sessionStorage.setItem('isHeaderBannerDismissed', 'true');
                        setShowBanner(false);
                      }}
                    >
                      &times;
                    </span>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )
      )}
    </>
  );
};

export default AdsBanner;
