import axios from 'axios';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../i18n';
import { logOff } from '../services/logoffService';
import { setJwtToken } from '@Services/jwtTokenValidator';

const instance = axios.create({
  baseURL: window.settings.VITE_BASE_URL,
});

instance.interceptors.request.use((request) => {
  request.headers.isMobile = false;
  const sessionReference = localStorage.getItem('sessionReference') ?? uuidv4();
  request.headers.sessionReference = sessionReference;
  localStorage.setItem('sessionReference', sessionReference);

  const appVersion = window.settings.VITE_VERSION;
  request.headers.appVersion = appVersion;

  setJwtToken(request.headers);

  //ATTACH UTM PARAMS TO HEADER FROM SESSION STORAGE
  sessionStorage.getItem('utmSource') &&
    (request.headers['Utmsource'] = sessionStorage.getItem('utmSource'));
  sessionStorage.getItem('utmMedium') &&
    (request.headers['Utmmedium'] = sessionStorage.getItem('utmMedium'));
  sessionStorage.getItem('utmCampaign') &&
    (request.headers['Utmcampaign'] = sessionStorage.getItem('utmCampaign'));
  sessionStorage.getItem('utmTerm') &&
    (request.headers['Utmterm'] = sessionStorage.getItem('utmTerm'));

  request.headers = {
    ...request.headers,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    language: i18n.language,
    lang: i18n.language,
    culture: i18n.language === 'en' ? 1 : 2,
  } as any;

  return request;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status >= 500) toast(i18n.t('translation:general_error'));
    else if (error.response?.status === 401) {
      await logOff(error.config);
    }else if(error.response?.status === 400 && error.response.data.errorCode === 'ISrvNA'){
      toast(i18n.t('translation:general_error'));
    }
    else if(error.response?.status === 400 && error.response.data.errorCode === 'KYC023'){
      toast(i18n.t('translation:KYC023'));
    }
    throw error;
  },
);

export default instance;
