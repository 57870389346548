import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { BrowserRouter } from 'react-router-dom';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { store } from '@Redux/store';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'swiper/css';
import 'swiper/css/navigation';
import './styles/_main.scss';
import  '@tameeni/shared-logic/theme.styles.css'
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from './AuthConfig.ts';
import * as Sentry from '@sentry/react';
import { getEnv } from './utils/envHelper.ts';
import { HelmetProvider } from 'react-helmet-async';

Sentry.init({
  dsn: ['prod'].includes(getEnv())
    ? 'https://f7e8dedf6b734d7dca0c42068ce783fe@o4507976399781888.ingest.de.sentry.io/4508119811358800'
    : undefined,
  integrations: [],
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <HelmetProvider>
  <AuthProvider {...oidcConfig}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </AuthProvider>
  </HelmetProvider>,
  // </React.StrictMode>,
);
