import { TAside, TFooter } from '@models/header.model';
import { Products } from '@tameeni/shared-logic';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';
import { getEnv } from 'src/utils/envHelper';

export function useHomeLinks() {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation([
    'routes',
    'header',
    'footer',
    'domestic-header',
    'domestic-footer',
    'mmp-header',
    'mmp-footer',
    'healthSmeHome',
    'psHome',
    'fleet-home',
  ]);

  const { user } = useAuth();
  const id = user?.profile?.IdentityNumber;
  const [searchParams] = useSearchParams();

  // Helper function to build query string
  const buildQueryString = (baseUrl: string) => {
    const queryString = searchParams.toString();
    const hasParams = baseUrl.includes('?');
    if (!queryString) return baseUrl;
    return `${baseUrl}${hasParams ? '&' : '?'}${queryString}`;
  };

  const HOME_LINKS = {
    [Products.CAR]: t('routes:routes.home'),
    [Products.HEALTH_INDIVIDUAL]: t('routes:routes.health-individual'),
    [Products.HEALTH_SME]: t('routes:routes.health-sme'),
    [Products.TRAVEL]: t('routes:routes.travel-home'),
    [Products.MEDICAL_MALPRACTICE]: t('routes:routes.mmp-home'),
    [Products.PROTECTION_AND_SAVING]: t('routes:routes.ps-home'),
    [Products.FLEET_SME]: t('routes:routes.fleet-home'),
  };

  const PRODUCTS_TO_SHOW = ['preprod', 'prod'].includes(getEnv())
    ? [
        Products.CAR,
        Products.HEALTH_SME,
        Products.MEDICAL_MALPRACTICE,
        Products.HEALTH_INDIVIDUAL,
        Products.TRAVEL,
      ]
    : [
        Products.CAR,
        Products.HEALTH_SME,
        Products.MEDICAL_MALPRACTICE,
        Products.HEALTH_INDIVIDUAL,
        Products.TRAVEL,
        Products.PROTECTION_AND_SAVING,
        Products.FLEET_SME,
      ];

  const INDIVIDUAL_PRODUCTS = ['preprod', 'prod'].includes(getEnv())
    ? [Products.CAR, Products.MEDICAL_MALPRACTICE, Products.HEALTH_INDIVIDUAL, Products.TRAVEL]
    : [
        Products.CAR,
        Products.MEDICAL_MALPRACTICE,
        Products.HEALTH_INDIVIDUAL,
        Products.TRAVEL,
        Products.PROTECTION_AND_SAVING,
      ];

  const SME_PRODUCTS = ['preprod', 'prod'].includes(getEnv())
    ? [Products.HEALTH_SME]
    : [Products.HEALTH_SME, Products.FLEET_SME];

  const STEP_ONE_LINKS = {
    [Products.CAR]: buildQueryString(`/UHome/PurchaseFlow?appflow=renewal${id ? `&ph=${id}` : ''}`),
    [Products.HEALTH_INDIVIDUAL]: buildQueryString(
      `${window.settings.HEALTH_INDIVIDUAL_ORIGIN}/${locale}`,
    ),
    [Products.HEALTH_SME]: buildQueryString(`${window.settings.VITE_HEALTH_SME_ORIGIN}/${locale}`),
    [Products.TRAVEL]: buildQueryString(
      `${window.settings.TRAVEL_ORIGIN}/${locale}/travel-journey?step=1`,
    ),
    [Products.MEDICAL_MALPRACTICE]: buildQueryString(`${window.settings.MMP_ORIGIN}/${locale}`),
    [Products.PROTECTION_AND_SAVING]: buildQueryString(`${window.settings.PS_ORIGIN}/${locale}`),
    [Products.FLEET_SME]: buildQueryString(
      `${window.settings.FLEET_SME_ORIGIN}/${locale}/sme-application/company-details/basic-details`,
    ),
  };

  const motorSupport = (t('header:aside', { returnObjects: true }) as TAside)?.support.map(
    (obj) => ({
      ...obj,
      icon: 'LinkArrow',
    }),
  );

  const healthSMEHeaderSupport = (
    t('healthSmeHome:header', { returnObjects: true }) as TAside
  )?.support.map((obj) => ({
    ...obj,
    href: `${window.settings.VITE_HEALTH_SME_ORIGIN}${obj.href}`,
    icon: 'LinkArrow',
  }));

  const domesticHeaderSupport = (
    t('domestic-header:aside', { returnObjects: true }) as TAside
  )?.support.map((obj) => ({
    ...obj,
    icon: 'LinkArrow',
  }));

  const psHeaderSupport = (t('psHome:header', { returnObjects: true }) as TAside)?.support.map(
    (obj) => ({
      ...obj,
      icon: 'LinkArrow',
    }),
  );

  const fleetHeaderSupport = (
    t('healthSmeHome:header', { returnObjects: true }) as TAside
  )?.support.map((obj) => ({
    ...obj,
    href: `${window.settings.FLEET_SME_ORIGIN}${obj.href}`,
    icon: 'LinkArrow',
  }));

  const HEADER_SUPPORT_LINKS: Partial<
    Record<Products, { label: string; href: string; icon: string }[]>
  > = {
    [Products.CAR]: motorSupport,
    [Products.HEALTH_INDIVIDUAL]: domesticHeaderSupport,
    [Products.HEALTH_SME]: healthSMEHeaderSupport,
    [Products.TRAVEL]: domesticHeaderSupport,
    [Products.MEDICAL_MALPRACTICE]: domesticHeaderSupport,
    [Products.PROTECTION_AND_SAVING]: psHeaderSupport,
    [Products.FLEET_SME]: fleetHeaderSupport,
  };

  const motorHeaderResources = (
    t('header:aside', { returnObjects: true }) as TAside
  )?.resources.map((obj) => ({
    ...obj,
    icon: 'LinkArrow',
  }));

  const domesticHeaderResources = (
    t('domestic-header:aside', { returnObjects: true }) as TAside
  )?.resources.map((obj) => ({
    ...obj,
    icon: 'LinkArrow',
  }));

  const healthSMEHeaderResources = (
    t('healthSmeHome:header', { returnObjects: true }) as TAside
  )?.resources.map((obj) => ({
    ...obj,
    href: `${window.settings.VITE_HEALTH_SME_ORIGIN}${obj.href}`,
    icon: 'LinkArrow',
  }));

  const mmpHeaderResources = (
    t('mmp-header:aside', { returnObjects: true }) as TAside
  )?.resources.map((obj) => ({
    ...obj,
    icon: 'LinkArrow',
    href: `${window.settings.MMP_ORIGIN}${obj.href}`,
  }));

  const psHeaderResources = (t('psHome:header', { returnObjects: true }) as TAside)?.resources.map(
    (obj) => ({
      ...obj,
      icon: 'LinkArrow',
      href: `${window.settings.PS_ORIGIN}${obj.href}`,
    }),
  );

  const fleetHeaderResources = (
    t('fleet-home:header', { returnObjects: true }) as TAside
  )?.resources.map((obj) => ({
    ...obj,
    icon: 'LinkArrow',
    href: `${window.settings.FLEET_SME_ORIGIN}${obj.href}`,
  }));

  const HEADER_RESOURCES_LINKS: Partial<
    Record<Products, { label: string; href: string; icon: string }[]>
  > = {
    [Products.CAR]: motorHeaderResources,
    [Products.HEALTH_INDIVIDUAL]: domesticHeaderResources,
    [Products.HEALTH_SME]: healthSMEHeaderResources,
    [Products.TRAVEL]: domesticHeaderResources,
    [Products.MEDICAL_MALPRACTICE]: mmpHeaderResources,
    [Products.PROTECTION_AND_SAVING]: psHeaderResources,
    [Products.FLEET_SME]: fleetHeaderResources,
  };

  const motorFooterResources = (
    t('footer:footer', { returnObjects: true }) as TFooter
  )?.resources.map((obj) => ({
    ...obj,
  }));

  const domesticFooterResources = (
    t('domestic-footer:footer', { returnObjects: true }) as TFooter
  )?.resources.map((obj) => ({
    ...obj,
  }));

  const mmpFooterResources = (
    t('mmp-footer:footer', { returnObjects: true }) as TFooter
  )?.resources.map((obj) => ({
    ...obj,
    url: `${window.settings.MMP_ORIGIN}${obj.url}`,
  }));

  const healthSMEFooterResources = (
    t('healthSmeHome:footer', { returnObjects: true }) as TFooter
  )?.resources.map((obj) => ({
    ...obj,
    url: `${window.settings.VITE_HEALTH_SME_ORIGIN}${obj.url}`,
  }));

  const psFooterResources = (t('psHome:footer', { returnObjects: true }) as TFooter)?.resources.map(
    (obj) => ({
      ...obj,
      url: `${window.settings.PS_ORIGIN}${obj.url}`,
    }),
  );

  const fleetFooterResources = (
    t('fleet-home:footer', { returnObjects: true }) as TFooter
  )?.resources.map((obj) => ({
    ...obj,
    url: `${window.settings.FLEET_SME_ORIGIN}${obj.url}`,
  }));

  const FOOTER_RESOURCES_LINKS: Partial<Record<Products, { label: string; url: string }[]>> = {
    [Products.CAR]: motorFooterResources,
    [Products.HEALTH_INDIVIDUAL]: domesticFooterResources,
    [Products.HEALTH_SME]: healthSMEFooterResources,
    [Products.TRAVEL]: domesticFooterResources,
    [Products.MEDICAL_MALPRACTICE]: mmpFooterResources,
    [Products.PROTECTION_AND_SAVING]: psFooterResources,
    [Products.FLEET_SME]: fleetFooterResources,
  };

  const motorFooterSupport = (t('footer:footer', { returnObjects: true }) as TFooter)?.support.map(
    (obj) => ({
      ...obj,
    }),
  );

  const domesticFooterSupport = (
    t('domestic-footer:footer', { returnObjects: true }) as TFooter
  )?.support.map((obj) => ({
    ...obj,
  }));

  const healthSMEFooterSupport = (
    t('healthSmeHome:footer', { returnObjects: true }) as TFooter
  )?.support.map((obj) => ({
    ...obj,
    url: `${window.settings.VITE_HEALTH_SME_ORIGIN}${obj.url}`,
  }));

  const psFooterSupport = (t('psHome:footer', { returnObjects: true }) as TFooter)?.support.map(
    (obj) => ({
      ...obj,
      url: `${window.settings.PS_ORIGIN}${obj.url}`,
    }),
  );

  const fleetFooterSupport = (
    t('fleet-home:footer', { returnObjects: true }) as TFooter
  )?.support.map((obj) => ({
    ...obj,
    url: `${window.settings.FLEET_SME_ORIGIN}${obj.url}`,
  }));

  const FOOTER_SUPPORT_LINKS: Partial<Record<Products, { label: string; url: string }[]>> = {
    [Products.CAR]: motorFooterSupport,
    [Products.HEALTH_INDIVIDUAL]: domesticFooterSupport,
    [Products.HEALTH_SME]: healthSMEFooterSupport,
    [Products.TRAVEL]: domesticFooterSupport,
    [Products.MEDICAL_MALPRACTICE]: domesticFooterSupport,
    [Products.PROTECTION_AND_SAVING]: psFooterSupport,
    [Products.FLEET_SME]: fleetFooterSupport,
  };

  return {
    HOME_LINKS,
    STEP_ONE_LINKS,
    HEADER_SUPPORT_LINKS,
    HEADER_RESOURCES_LINKS,
    FOOTER_RESOURCES_LINKS,
    FOOTER_SUPPORT_LINKS,
    PRODUCTS_TO_SHOW,
    INDIVIDUAL_PRODUCTS,
    SME_PRODUCTS,
  };
}
