import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const MetaTags = ({
  title,
  description,
  origin,
  kw,
}: {
  title: string;
  description: string;
  origin?: string;
  kw?: string;
}) => {
  const {
    i18n: { language: locale },
  } = useTranslation();
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        property='og:image'
        content={window.settings.REACT_RESOURCES_BASE_URL + '/Resources/images/header-logo.svg'}
      />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />

      <meta
        property='twitter:image'
        content={window.settings.REACT_RESOURCES_BASE_URL + '/Resources/images/header-logo.svg'}
      />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={window.location.href} />

      <meta name='title' content={title} />
      <meta name='description' content={description} />
      <meta name='keywords' content={kw ?? title} />

      <link rel='alternate' hrefLang={`${locale}-SA`} href={`${origin}/${locale}`} />
    </Helmet>
  );
};

export default MetaTags;
