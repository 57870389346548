import { Header } from '@tameeni/header';
import { Footer } from '@tameeni/footer';
import useInitLanguage from '@Hooks/useInitLanguage';
import { useAuth } from 'react-oidc-context';
import useModal from '@Hooks/useModal';
import LoginPopup from '@Components/common/LoginPopup';
import { useTranslation } from 'react-i18next';
import { ReactNode, useState } from 'react';
import { dataLayerPush } from '@Services/ga4';
import { GA4Events } from '@Services/ga4Events';
import TameeniLoader from '@Components/common/TameeniLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import { Products } from '@tameeni/shared-logic';
import { useHomeLinks } from './hooks/useHomeLinks';
import AdsBanner from './AdsBanner';

// Constants

const PRODUCT_PATHS = {
  [Products.CAR]: ['/', '/en'],
  [Products.MEDICAL_MALPRACTICE]: ['/mmp', '/en/mmp'],
  [Products.TRAVEL]: ['/travel', '/en/travel'],
  [Products.HEALTH_SME]: ['/health-sme', '/en/health-sme'],
  [Products.HEALTH_INDIVIDUAL]: ['/health', '/en/health'],
  [Products.PROTECTION_AND_SAVING]: ['/ps', '/en/ps'],
  [Products.FLEET_SME]: ['/fleet', '/en/fleet'],
};

const PAGES_WITHOUT_LOGIN = [
  '/mmp',
  '/en/mmp',
  '/travel',
  '/en/travel',
  '/health-sme',
  '/en/health-sme',
  '/health',
  '/en/health',
  '/ps',
  '/en/ps',
  '/fleet',
  '/en/fleet',
];

export default function Layout({ children }: { children: ReactNode }) {
  const { t, i18n } = useTranslation(['translation', 'routes']);
  const locale = i18n.language as 'ar' | 'en';
  const { switchLanguage, isLoading: isSwitchingLanguage } = useInitLanguage();
  const auth = useAuth();
  const { showModal, handleCloseModal, handleShowModal, modalTarget } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    HOME_LINKS,
    HEADER_SUPPORT_LINKS,
    HEADER_RESOURCES_LINKS,
    FOOTER_RESOURCES_LINKS,
    FOOTER_SUPPORT_LINKS,
    PRODUCTS_TO_SHOW,
  } = useHomeLinks();

  // Handlers
  const handleLogin = () => {
    document.getElementById('Login-popup-trigger')?.click();
  };

  const handleLogout = () => {
    if (auth.isAuthenticated) {
      setIsLoading(true);
      dataLayerPush(GA4Events.logout.event, []);
      auth.signoutSilent().then(() => {
        navigate('/', { replace: true });
        localStorage.clear();
        setIsLoading(false);
      });
    }
  };

  // Data
  const loginMenuItems = [
    {
      label: t('translation:myAccount'),
      href: '/individual/profilepage/dashboard',
      icon: 'Settings',
      activeIcon: 'Settings',
    },
  ];

  const getActiveProduct = () => {
    for (const [product, paths] of Object.entries(PRODUCT_PATHS)) {
      if (paths.includes(pathname)) {
        return product as Products;
      }
    }
    return Products.CAR; // Default product
  };

  if (isLoading || isSwitchingLanguage) return <TameeniLoader />;

  return (
    <>
      {!PAGES_WITHOUT_LOGIN.includes(pathname) && <AdsBanner />}
      <Header
        activeProduct={getActiveProduct()}
        locale={locale}
        isLogged={auth.isAuthenticated}
        isLoginEnabled={!PAGES_WITHOUT_LOGIN.includes(pathname)}
        productsToShow={PRODUCTS_TO_SHOW}
        additionalSupportLinks={HEADER_SUPPORT_LINKS[getActiveProduct()]?.map((link) => ({
          ...link,
          label: t(link.label),
          activeIcon: link.icon,
        }))}
        resourcesLinks={HEADER_RESOURCES_LINKS[getActiveProduct()]?.map((link) => ({
          ...link,
          label: t(link.label),
          activeIcon: link.icon,
        }))}
        user={
          auth.isAuthenticated
            ? {
                name: auth.user?.profile?.email as string,
                id: auth.user?.profile?.IdentityNumber as string,
              }
            : undefined
        }
        onLoginClick={handleLogin}
        rootHref={`/${locale === 'en' ? 'en' : ''}`}
        onLanguageChange={switchLanguage}
        loginMenuItems={loginMenuItems}
        onLogoutClick={handleLogout}
        className='lng-toggle-catcher'
        links={HOME_LINKS}
      />
      <main>{children}</main>

      <Footer
        locale={locale}
        rootHref={`/${locale === 'en' ? 'en' : ''}`}
        productsToShow={PRODUCTS_TO_SHOW}
        links={HOME_LINKS}
        resourcesLinks={FOOTER_RESOURCES_LINKS[getActiveProduct()]}
        supportLinks={FOOTER_SUPPORT_LINKS[getActiveProduct()]}
      />

      <a
        id='Login-popup-trigger'
        className='hidden'
        href='#Login-popup'
        data-target-modal='#Login-popup'
        data-testid='login-popup'
        onClick={(e: React.MouseEvent<HTMLElement>) => handleShowModal(e)}
      />
      {showModal && modalTarget === '#Login-popup' && <LoginPopup handleClose={handleCloseModal} />}
    </>
  );
}
