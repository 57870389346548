import Img from '@Components/common/Img';
import MetaTags from '@Components/common/OpenGraphTags/MetaTags';
import { useHomeLinks } from '@Components/Layout/hooks/useHomeLinks';
import { IFAQsQuestion } from '@models/faq.model';
import { Alert } from '@mui/material';
import {
  FaqSection,
  Hero,
  HeroSection,
  Home,
  MainNav,
  MobileAppBanner,
  PartnerItem,
  Partners,
  SingleShipmentSection,
  TameeniProducts,
  WhyTameeniCard,
  WhyTameeniSection,
} from '@tameeni/home';
import { IconName } from '@tameeni/icons';
import { cn, Products } from '@tameeni/shared-logic';
import { FaqAccordion, FaqItem } from '@tameeni/ui';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const IC_LOGOS = ['walaa'];

export default function PSHomePage() {
  const {
    t,
    i18n: { language },
  } = useTranslation(['psHome', 'routes', 'home', 'meta']);
  const [selectedOption, setSelectedOption] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const { HOME_LINKS, STEP_ONE_LINKS, PRODUCTS_TO_SHOW, INDIVIDUAL_PRODUCTS, SME_PRODUCTS } =
    useHomeLinks();
  const locale = language as 'ar' | 'en';

  useEffect(() => {
    localStorage.removeItem('ph');
    localStorage.removeItem('wasAuth');
  }, []);

  useEffect(() => {
    if (selectedOption) {
      setShowErrorMsg(false);
    }
  }, [selectedOption]);

  const whyTameeniCards = Array.from({ length: 8 }, (_, i) => ({
    description: t(`psHome:why_tameeni.cards.st${i + 1}.description`),
    icon: [
      'PsBenefitProgram',
      'PsBenefitProtection',
      'PsBenefitStrategy',
      'PsBenefitCompare',
      'PsBenefitIssue',
      'PsBenefitPayment',
      'PsBenefitMonitor',
      'PsBenefitAlert',
    ][i],
    title: t(`psHome:why_tameeni.cards.st${i + 1}.title`),
  }));

  const mulipleReasons = Array.from({ length: 7 }, (_, i) => ({
    description: t(`psHome:muliple_reasons.cards.st${i + 1}.description`),
    title: t(`psHome:muliple_reasons.cards.st${i + 1}.title`),
  }));

  // Data
  const heroOptions = [
    { label: t('psHome:hero.selection_inputs.1'), icon: 'PsOptionEducation', value: '2' },
    { label: t('psHome:hero.selection_inputs.2'), icon: 'PsOptionRetirement', value: '3' },
    { label: t('psHome:hero.selection_inputs.3'), icon: 'PsOptionInvestment', value: '4' },
  ];

  // Handlers
  const handleSelectOption = (value: string) => {
    scrollAndHighlight();
    if (selectedOption === value) {
      setSelectedOption('');
    } else {
      setSelectedOption(value);
      setShowErrorMsg(false);
    }
  };

  const handleGetQuote = () => {
    const route = `${STEP_ONE_LINKS.protection_and_saving}${
      selectedOption && `?product=${selectedOption}`
    }`;
    if (selectedOption !== '') {
      window.location.href = route;
    } else {
      setShowErrorMsg(true);
    }
  };

  const scrollAndHighlight = () => {
    const element = document.getElementById('get-quote-action');
    if (element) {
      // Scroll into view button
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });

      // Highlight effect
      element.style.transition = 'opacity 0.5s ease';
      element.style.opacity = '0.5';
      // Remove highlight after a short delay
      setTimeout(() => {
        element.style.opacity = '1';
      }, 800);
    }
  };

  return (
    <>
      <MetaTags
        title={t('meta:ps.meta.title')}
        description={t('meta:ps.meta.description')}
        origin={window.settings.PS_ORIGIN}
        kw={t('meta:ps.meta.kw')}
      />
      <Home currentActiveTab={Products.PROTECTION_AND_SAVING} locale={locale}>
        <MainNav
          currentActiveTab={Products.PROTECTION_AND_SAVING}
          productsToShow={PRODUCTS_TO_SHOW}
          locale={locale}
          links={HOME_LINKS}
        />

        <HeroSection>
          <Hero.HeroContent title={t('psHome:hero.title')} subTitle={t('psHome:hero.description')}>
            <Hero.Selections>
              <div className='flex flex-col gap-8 w-full'>
                <div className='flex flex-col gap-4 w-full'>
                  <div className='text-t2 lg:text-md font-semibold'>
                    {t('psHome:hero.selection_title')}
                  </div>

                  <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4'>
                    {heroOptions.map((option) => (
                      <Hero.Option
                        key={option.value}
                        label={option.label}
                        icon={option.icon as IconName}
                        value={option.value}
                        onSelect={() => handleSelectOption(option.value)}
                        isSelected={selectedOption === option.value}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </Hero.Selections>
            {showErrorMsg && (
              // TODO: Should be changed into "Tameeni Alert DS" after DS team create it
              <Alert
                severity='error'
                className={cn(locale == 'en' && 'font-roboto', locale == 'ar' && 'font-kufi gap-2')}
              >
                {t('psHome:hero.error_msg')}
              </Alert>
            )}
            <div id='get-quote-action'>
              <Hero.Actions>
                <Hero.ActionButton
                  label={t('psHome:hero.get_quote')}
                  actionType='primary'
                  onClick={handleGetQuote}
                />
              </Hero.Actions>
            </div>
          </Hero.HeroContent>
          <Hero.MediaSection
            type='image'
            imageSrc={{
              desktop: '/Resources/images/ps-home/hero-ps.webp',
              mobile: '/Resources/images/ps-home/hero-ps.webp',
            }}
            alt='Tameeni Protection and Saving'
          />
        </HeroSection>

        <Partners includedSponsors={['IA']} isCentered>
          {IC_LOGOS?.map((logo) => {
            return (
              <PartnerItem key={logo}>
                <Img
                  className='h-[30px] hover:opacity-70'
                  src={`/Resources/images/ICLogos/${logo}.svg`}
                  title={logo}
                />
              </PartnerItem>
            );
          })}
        </Partners>

        <div className='py-8 md:py-16'>
          <TameeniProducts
            locale={locale}
            title={t('healthSmeHome:tameeni_products.title')}
            subtitle={t('healthSmeHome:tameeni_products.subtitle')}
            links={STEP_ONE_LINKS}
            individualProducts={INDIVIDUAL_PRODUCTS}
            corporateProducts={SME_PRODUCTS}
          />
        </div>

        <SingleShipmentSection
          contentTitle={t('psHome:why_protection.title')}
          sectionSubtitle=''
          sectionTitle=''
        >
          <React.Fragment key='.0'>
            <span>{t('psHome:why_protection.description')}</span>
          </React.Fragment>
        </SingleShipmentSection>
        <div className='w-full overflow-hidden'></div>
        <WhyTameeniSection title={t('psHome:why_tameeni.title')}>
          {whyTameeniCards.map((item) => (
            <WhyTameeniCard
              key={item.icon}
              icon={item.icon as IconName}
              title={''}
              description={item.description}
            />
          ))}
        </WhyTameeniSection>

        <div className='bg-slate-800 py-8 lg:py-16'>
          <WhyTameeniSection className='text-white my-0' title={t('psHome:muliple_reasons.title')}>
            {mulipleReasons.map((item) => (
              <WhyTameeniCard
                key={item.title}
                title={item.title}
                description={item.description}
                className='bg-slate-700 text-white'
                descriptionClassName='text-slate-200 whitespace-pre-line'
              />
            ))}
          </WhyTameeniSection>
        </div>

        <FaqSection
          title={t('psHome:tips.title')}
          link={`${window.settings.PS_ORIGIN}/${locale}/faqs`}
          linkLabel={t('psHome:tips.viewMore')}
        >
          <FaqAccordion>
            {(t('psHome:tips.questions', { returnObjects: true }) as IFAQsQuestion[])?.map(
              (question) => {
                return (
                  <FaqItem key={question?.q} title={question?.q}>
                    {question?.a}
                  </FaqItem>
                );
              },
            )}
          </FaqAccordion>
        </FaqSection>
        {/* Mobile App Banner */}
        <div className='mt-32 overflow-x-clip'>
          <MobileAppBanner
            locale={locale}
            title={t('home:home_app.title')}
            text={t('home:home_app.subtitle')}
          />
        </div>
      </Home>
    </>
  );
}
