import { Link } from 'react-router-dom';
import Img from '@Components/common/Img';
import { Icon, IconName } from '@tameeni/icons';
import { Products } from '@tameeni/shared-logic';
import { IFAQsQuestion } from '@models/faq.model';
import { FaqAccordion, FaqItem } from '@tameeni/ui';
import { Trans, useTranslation } from 'react-i18next';
import MetaTags from '@Components/common/OpenGraphTags/MetaTags';
import { useHomeLinks } from '@Components/Layout/hooks/useHomeLinks';

import {
  Hero,
  Home,
  Banners,
  MainNav,
  Partners,
  FaqSection,
  BannerItem,
  HeroSection,
  PartnerItem,
  WhyTameeniCard,
  MobileAppBanner,
  TameeniProducts,
  WhyTameeniSection,
} from '@tameeni/home';

const IC_LOGOS = [
  'UCA',
  'Buruj',
  'Enaya',
  'Tree',
  'GulfUnion',
  'AlSagr',
  'Der3',
  'AICC',
  'Salama',
  'walaa',
  'ACIG',
  'AJT',
  'GGCIC',
  'ART',
  'TUCI',
  'ACI',
];

export default function HealthSMEHomePage() {
  const {
    t,
    i18n: { language },
  } = useTranslation(['healthSmeHome', 'routes', 'home', 'meta']);

  const locale = language as 'ar' | 'en';
  const { HOME_LINKS, STEP_ONE_LINKS, PRODUCTS_TO_SHOW, INDIVIDUAL_PRODUCTS, SME_PRODUCTS } =
    useHomeLinks();

  const getQuotationsHandler = () => {
    window.location.href = STEP_ONE_LINKS.health_sme;
  };

  const whyTameeniCards = Array.from({ length: 6 }, (_, i) => ({
    description: t(`healthSmeHome:why_tameeni.cards.st${i + 1}.description`),
    icon: ['compare', 'saveTime', 'cchiUpload', 'detailedInfo', 'providersList', 'classes'][i],
    title: t(`healthSmeHome:why_tameeni.cards.st${i + 1}.title`),
  }));

  const whatInsuranceCoverage = Array.from({ length: 3 }, (_, i) => ({
    description: t(`healthSmeHome:what_insurance_coverage.cards.st${i + 1}.description`),
    title: t(`healthSmeHome:what_insurance_coverage.cards.st${i + 1}.title`),
  }));

  const mulipleReasons = Array.from({ length: 4 }, (_, i) => ({
    description: t(`healthSmeHome:muliple_reasons.cards.st${i + 1}.description`),
    title: t(`healthSmeHome:muliple_reasons.cards.st${i + 1}.title`),
  }));

  const stepsToBuy = Array.from({ length: 4 }, (_, i) => ({
    key: t(`healthSmeHome:steps_to_buy.steps.st${i + 1}.key`),
    title: t(`healthSmeHome:steps_to_buy.steps.st${i + 1}.title`),
  }));

  const whatAffects = Array.from({ length: 5 }, (_, i) => ({
    key: t(`healthSmeHome:what_affects.affects.st${i + 1}.key`),
    icon: t(`healthSmeHome:what_affects.affects.st${i + 1}.icon`),
    title: t(`healthSmeHome:what_affects.affects.st${i + 1}.title`),
  }));

  return (
    <>
      <MetaTags
        title={t('meta:health-sme.meta.title')}
        description={t('meta:health-sme.meta.description')}
        origin={window.settings.VITE_HEALTH_SME_ORIGIN}
        kw={t('meta:health-sme.meta.kw')}
      />
      <Home currentActiveTab={Products.HEALTH_SME} locale={locale}>
        {/** Main Nav Section */}
        <MainNav
          currentActiveTab={Products.HEALTH_SME}
          productsToShow={PRODUCTS_TO_SHOW}
          locale={locale}
          links={HOME_LINKS}
        />
        {/** Hero Section */}
        <HeroSection>
          <Hero.HeroContent
            title={t('healthSmeHome:hero.title')}
            subTitle={t('healthSmeHome:hero.description')}
          >
            <Hero.Actions>
              <Hero.ActionButton
                label={t('healthSmeHome:hero.getQuotations')}
                actionType='primary'
                onClick={getQuotationsHandler}
              />
            </Hero.Actions>

            <p className='text-slate-600'>
              <Trans
                i18nKey='healthSmeHome:hero.lookingFor'
                components={{
                  a: (
                    <Link
                      to={t('routes:routes.health-individual')}
                      className='text-primary underline'
                    />
                  ),
                }}
              />
            </p>
          </Hero.HeroContent>
          <Hero.MediaSection
            type='image'
            imageSrc={{
              mobile: '/Resources/images/home/ramadan/health-sme-ramadan-mobile.png',
              desktop: '/Resources/images/home/ramadan/health-sme-ramadan-web.png',
            }}
            alt='Tameeni Health SME'
          />
        </HeroSection>

        {/* Partners (IC Logos) Section */}
        <Partners includedSponsors={['IA', 'Monshaat', 'Mazaya']}>
          {IC_LOGOS?.map((logo) => {
            return (
              <PartnerItem key={logo}>
                <Img
                  className='h-[30px] hover:opacity-70 max-w-none'
                  src={`/Resources/images/ICLogos/${logo}.svg`}
                  title={logo}
                />
              </PartnerItem>
            );
          })}
        </Partners>

        {/*  Zen HR Banners Section*/}
        <div className='pt-8 lg:pt-16'>
          <Banners cols={1}>
            <a href={`https://www.zenhr.com/${locale}/about`} target='_blank' rel='noreferrer'>
              <BannerItem
                imageSrc={{
                  desktop: `/Resources/images/health-sme-home/zenHr-${locale}.png`,
                  mobile: `/Resources/images/health-sme-home/zenHr-${locale}.png`,
                }}
                alt='ZenHR Banner'
              />
            </a>
          </Banners>
        </div>

        {/* Tameeni Products Section */}
        <div className='py-8 md:py-16'>
          <TameeniProducts
            locale={locale}
            title={t('healthSmeHome:tameeni_products.title')}
            subtitle={t('healthSmeHome:tameeni_products.subtitle')}
            links={STEP_ONE_LINKS}
            corporateProducts={SME_PRODUCTS}
            individualProducts={INDIVIDUAL_PRODUCTS}
          />
        </div>

        {/* Why Tameeni Section */}
        <WhyTameeniSection
          subtitle={t('healthSmeHome:why_tameeni.subtitle')}
          title={t('healthSmeHome:why_tameeni.title')}
        >
          {whyTameeniCards.map((item) => (
            <WhyTameeniCard
              key={item.icon}
              icon={item.icon as IconName}
              title={item.title}
              description={item.description}
            />
          ))}
        </WhyTameeniSection>

        {/* What Insurance Coverage Section */}
        <div className='py-1'>
          <WhyTameeniSection
            title={t('healthSmeHome:what_insurance_coverage.title')}
            subtitle={t('healthSmeHome:what_insurance_coverage.subtitle')}
          >
            {whatInsuranceCoverage.map((item) => (
              <WhyTameeniCard
                key={item.title}
                title={item.title}
                description={item.description}
                hasBorders
                className='bg-white shadow-xs'
              />
            ))}
          </WhyTameeniSection>
        </div>

        {/* Multiple reasons Coverage Section */}
        <div className='bg-slate-800 py-8 lg:py-16'>
          <WhyTameeniSection
            className='text-white my-0'
            title={t('healthSmeHome:muliple_reasons.title')}
          >
            {mulipleReasons.map((item) => (
              <WhyTameeniCard
                key={item.title}
                title={item.title}
                description={item.description}
                className='bg-slate-700 text-white'
                descriptionClassName='text-slate-200 whitespace-pre-line'
              />
            ))}
          </WhyTameeniSection>
        </div>

        {/* What affects Section */}
        <section className='bg-slate-50 py-12 lg:py-24'>
          <div className='box'>
            <h1 className='text-h1 lg:text-d2 text-center mb-2'>
              {t('healthSmeHome:what_affects.title')}
            </h1>
            <h2 className='text-h4 text-slate-600 text-center mb-8'>
              {t('healthSmeHome:what_affects.subtitle')}
            </h2>
            <div className='flex flex-wrap justify-center -mx-2'>
              {whatAffects?.map((item) => {
                return (
                  <div key={item.key} className='w-1/2 md:w-1/3 lg:w-1/5 mb-4 px-2'>
                    <div className='bg-white rounded-lg py-6 px-4 flex flex-col items-center justify-center gap-6'>
                      <div className='h-14'>
                        <img src={item.icon} alt={item.title} className='max-h-full' />
                      </div>
                      <h6 className='text-base text-slate-900 mb-0'>{item.title}</h6>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {/* Stept To Buy HI Section */}
        <section className='relative bg-blue-100'>
          <div className='absolute w-full md:w-4/12 h-full end-0 top-0 bg-white z-[1] hidden md:block'></div>
          <div className='box '>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full md:w-7/12 px-4'>
                <div className='py-5 lg:py-10'>
                  <h2 className='text-3xl mb-6'>{t('healthSmeHome:steps_to_buy.title')}</h2>
                  <ul>
                    {stepsToBuy?.map((step, i) => {
                      return (
                        <li key={step.key} className='flex items-center gap-2 mb-4'>
                          <span className='inline-flex justify-center items-center rounded-full text-primary bg-white text-sm font-semibold w-6 h-6 p-1'>
                            {i + 1}
                          </span>
                          <p className='text-slate-600 text-lg mb-0'>{step.title}</p>
                        </li>
                      );
                    })}
                  </ul>
                  <button
                    className='bg-blue-200 text-primary font-medium rounded-sm py-2 px-4 inline-flex items-center gap-1.5'
                    type='button'
                    onClick={getQuotationsHandler}
                  >
                    <span>{t('healthSmeHome:steps_to_buy.getQuotations')}</span>
                    <Icon name='Chevron' className={language === 'ar' ? 'rotate-180' : ''} />
                  </button>
                </div>
              </div>
              <div className='w-full md:w-5/12 px-4 bg-white relative z-[2]'>
                <div className='py-5 lg:py-10 flex items-center justify-center'>
                  <Img src={t('healthSmeHome:steps_to_buy.gif')} />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQs Section */}
        <FaqSection
          title={t('healthSmeHome:tips.title')}
          link={`${window.settings.VITE_HEALTH_SME_ORIGIN}/${locale}/faq-question`}
          linkLabel={t('healthSmeHome:tips.viewMore')}
        >
          <FaqAccordion>
            {(t('healthSmeHome:tips.questions', { returnObjects: true }) as IFAQsQuestion[])?.map(
              (question) => {
                return (
                  <FaqItem key={question?.q} title={question?.q}>
                    {question?.a}
                  </FaqItem>
                );
              },
            )}
          </FaqAccordion>
        </FaqSection>

        {/* Mobile App Banner */}
        <div className='mt-32 overflow-x-clip'>
          <MobileAppBanner
            locale={locale}
            title={t('home:home_app.title')}
            text={t('home:home_app.subtitle')}
          />
        </div>
      </Home>
    </>
  );
}
