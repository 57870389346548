import { dataLayerPush } from '@Services/ga4';
import { GA4Events } from '@Services/ga4Events';
import { IRoutes } from '@models/routes.model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
export default function useInitLanguage() {
  const { t, i18n } = useTranslation('routes');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const language = i18n.language;
  const location = useLocation();
  const navigate = useNavigate();

  const getAndNavigateToRoute = (pathURL?: string, routesObj?: IRoutes['routes']) => {
    if (!pathURL) pathURL = decodeURI(location.pathname);
    if (!routesObj) routesObj = t('routes:routes', { returnObjects: true }) as IRoutes['routes'];
    const arRoutes = t('routes:routes', { lng: 'ar', returnObjects: true }) as any;
    const enRoutes = t('routes:routes', { lng: 'en', returnObjects: true }) as any;
    for (const [key, value] of Object.entries(routesObj)) {
      if (value.toLocaleLowerCase() === pathURL.toLocaleLowerCase()) {
        navigate(
          {
            pathname: `${t(`routes:routes.${key}`)}`,
            search: location.search,
          },
          { replace: true },
        );
      } else if (pathURL.toLocaleLowerCase() === arRoutes[key] && language !== 'ar') {
        switchLanguage();
      } else if (pathURL.toLocaleLowerCase() === enRoutes[key] && language !== 'en') {
        switchLanguage();
      }
    }
    setIsLoading(false);
  };

  const switchLanguage = async (e?: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault();
    setIsLoading(true);
    dataLayerPush(GA4Events.languageChange.event, [
      {
        key: GA4Events.languageChange.params.language,
        value: language === 'en' ? 'arabic' : 'english',
      },
    ]);
    const pathURL = location.pathname;
    const routesObj = t('routes:routes', { returnObjects: true }) as IRoutes['routes'];
    await i18n.changeLanguage(language === 'en' ? 'ar' : 'en', () => {
      document.documentElement.lang = i18n.language;
      document.documentElement.lang = i18n.language;
      getAndNavigateToRoute(decodeURI(pathURL), routesObj);
      window.location.reload();
    });
  };

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    getAndNavigateToRoute();
  }, []);

  return { switchLanguage, isLoading };
}
